.footer {
  background-color: #000;
  color: var(--contentTextColor);
  z-index: 1;
  padding-top: 20%;
}
.footer_wrapper {
  background-color: #000303;
}
.footer-form_title {
  font-size: 32px;
  font-weight: 400;
}
.close-button {
  position: absolute;
  top: -20px;
  right: 40px;
  width: 40px;
  height: 40px;
}
.close-button img {
  width: 40px;
  height: 40px;
}
.footer_content {
  font-weight: 300;
  font-size: 22px;
}
.modal-content {
  background-image: url('../../../../assets/components/bg_button.png');
  background-size: contain;
  background-repeat: no-repeat;
  padding: 40px 0;
  background-color: transparent !important;
  border: none !important;
}
.font-weight-normal {
  font-weight: 400;
}

.footer-title {
  font-size: 26px;
  font-weight: 400;
}

.footer-form_text {
  font-size: 20px;
}
.text-green {
  color: #36ae48;
}
.text-ellipsis {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  max-width: 70%;
}
.footer_button {
  background-color: var(--footerButtonColor);
}

.footer_bg {
  z-index: -1;
}
.footer_form_wrapper {
  background-image: url('../../../../assets/components/form-bg.png');
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  z-index: 2;
  height: 500px;
  margin-top: -90px;
  padding-top: 80px;
  position: relative;
}

@media screen and (max-width: 768px) {
  .footer_logo .logo {
    width: 100px !important;
  }
  .footer_form_wrapper {
    margin-top: -40px;
  }
}

.w-38, .w-38 img {
  width: 38px;
}
