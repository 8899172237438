body {
  margin: 0;
  font-family: "Rowdies", sans-serif !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;


}



.rowdies-light {
  font-family: "Rowdies", sans-serif;
  font-weight: 300;
  font-style: normal;
}

.rowdies-regular {
  font-family: "Rowdies", sans-serif;
  font-weight: 400;
  font-style: normal;
}

.rowdies-bold {
  font-family: "Rowdies", sans-serif;
  font-weight: 700;
  font-style: normal;
}


