.section2 {
  background: linear-gradient(360deg, #0c1720 31.02%, #354a12 100%);
}

.section2_content {
  width: 1000px;
  color: #fff;
}

.section2 .container {
  max-width: 1400px;
}
