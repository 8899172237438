.board-title_board {
  width: 40% !important;
}

.board_content {
  position: relative;
  color: #fff;
  word-wrap: break-word;
  padding-top: 130px;
  flex-direction: column;
  align-items: center;
  gap: 8px;
}
.board_container {
  height: 100vh;
}

.board-content_text {
  max-width: 1000px;
  font-size: 20px;
  line-height: 35px;
  font-weight: 400;
  margin: 0px;
  text-align: justify;
  margin-bottom: 10px;
  /* word-wrap: break-word; */
}

.top-5 {
  top: 5% !important;
}

.top-10 {
  top: 10% !important;
}

.translate_right {
  transform: translate(-35%, -50%);
}

.translate_left {
  transform: translate(-60%, -50%);
}

.arrow {
  max-width: 15%;
  top: 40%;
}

@media screen and (max-width: 556px) {
  .board-title_board {
    width: 30% !important;
  }
  .board_container {
    background: none !important;
  }
  .board_content {
    overflow: hidden;
    overflow-y: scroll;
    margin-top: 130px;
    height: calc(100vh - 130px);
  }
  .board_container .board-content_text {
    font-size: 16px !important;
  }
  .board-content_text:first-child {
    margin-top: 200px;
  }
}
.board_content::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
.board_content {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}

@media screen and (min-width: 990px) {
  .board_content {
    position: absolute;
    margin-top: 0;
    color: var(--contentTextColor);
  }

  .board-content_text {
    font-size: 20px;
    line-height: 40px;

    /* word-wrap: break-word; */
  }
}
