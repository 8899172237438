.section1{
    background-image: url('../../../../assets//section/section1/background.png');
    height: 50vh;
    background-size: cover;
    background-position: center;
}
.section1_text{
    font-size: 25px;
    font-weight: 400;
    color: #000;
    margin-bottom: 20px;
}


@media (min-width: 576px) {
    
    .section1_text{
        font-size: 35px;
    }
}
@media (min-width: 768px) {

    
    .section1_text{
        font-size: 48px;
    }
}

@media (min-width: 992px) {
    .section1{
        height: 100vh;
    }
}

@media (min-width: 1400px) {
    
}