.section4 {
  background: linear-gradient(360deg, #03383b 0%, #0a1f23 50%, #6c3021 100%);
  gap: 5rem;
}

.section4_card {
  width: 380px;
  height: 460px;
  background-image: url('../../../../assets/section/section4/hexagon_board.png');
  background-size: cover;
  padding: 140px 15px 0px 30px;
}

.card_leaf {
  width: 80%;
  top: 8%;
  left: 45%;
}
.card_content {
  top: 20%;
}
.card_title {
  top: 18%;
  color: #fff;
  font-size: 32px;
  background: linear-gradient(180deg, #2fe863 20%, #1f82ca 81.67%);
  -webkit-background-clip: text;
  -webkit-text-stroke: 2px transparent;
  text-shadow: 4px 2px 0px #0000004d;
}

.card_text p {
  font-size: 12px;
  font-weight: 300;
  color: var(--contentTextColor);
}

@media (min-width: 390px) {
  .section4_title {
    width: 30% !important;
  }
}

@media (min-width: 567px) {
  .section4_card {
    width: 380px;
  }

  .card_text p {
    font-size: 18px;
    font-weight: 400;

    margin-bottom: 5px;
  }
}
