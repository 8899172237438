.title{
    font-size: 27px;
}

.content{
    font-size: 18px;
}

.effect{
    width: 100%;
    color: var(--contentTextColor);
    font-weight: 700;
}

@media (min-width: 990px) {
    .title{
        font-size: 38px;
    }
}



