.section3 {
  background-image: url('../../../../assets/section/section3/background.png');
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  background-color: black;
}
.section3_wrapper{
  background-color: #0C1720;
}
.section3_pic img {
  border-width: 15px !important;
}

.section3_title {
  width: 40% !important;
}

.section3-pic_text {
  bottom: 15%;
  right: 45px;
  background: linear-gradient(180deg, #817400 20%, #783e08 81.67%);
  -webkit-background-clip: text;
  -webkit-text-stroke: 2px transparent;
  text-align: right;
  font-weight: 700;
  font-size: 24px;
  height: 70%;
  display: flex;
  flex-direction: column-reverse;
  justify-content: space-between;
}

.section3-content_middle {
  background-color: #000000cc;
  color: var(--contentTextColor);
  font-size: 16px;
  font-weight: 400;
  line-height: 28px;
}

@media (min-width: 390px) {
  .section3_title {
    width: 30% !important;
  }
}

@media (min-width: 768px) {
  .section3_content {
    font-size: 14px;
  }
  .pic_border {
    border: #ffffff solid 1px;
  }

  .section3_button {
    width: 50% !important;
  }
}
@media (min-width: 990px) {
  .content {
    font-size: 16px;
  }
  .section3_content {
    font-size: 18px;
  }
}

@media (min-width: 1200px) {
  .title {
    font-size: 40px;
  }

  .content {
    font-size: 20px;
  }
}
