.header{
    padding: 45px 0;
    /* background-color: #fefdf3; */
}

 
.nav_item{
    font-size: 19px !important;
    color: #00231D !important;
}

@media (min-width: 768px) {
    .navbar_logo{
        width: 10%;
    }
}

@media (min-width: 1200px) {
    .navbar_logo{
        width: 10%;
    }
}