.section5 {
  background: linear-gradient(180deg, #03383b 0%, #000000 100%);
  padding-bottom: 50px;
}

.game_item {
  background-size: contain;
  background-repeat: no-repeat;
  position: relative;
  z-index: 10;
  width: 150px;
  height: 170px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.section5-img_button {
  cursor: pointer;
}
.section5-img {
  max-width: 88px;
  margin: auto;
}

.game_item .position-absolute {
  z-index: -1;
}
.section-5_image_wrapper {
  width: 400px;
  margin: auto;
}
@media screen and (max-width: 768px) {
  .section-5_image_wrapper {
    width: 350px;
  }
  .section-5_image_wrapper img {
    display: none;
  }
  .section-5_image_wrapper img:first-child {
    display: block;
  }
  .section5-img_slideShow {
    min-height: 200px !important;
  }
}
.section5-img_slideShow {
  min-height: 350px;
}
.section5-img_slideShow {
  top: 25%;
  left: 13%;
}
.section-5_title {
  margin-bottom: 50px;
}
