

.wrapper{
    padding: 7% 0;
}

@media (min-width: 1400px) { 
    .wrapper{
        padding: 10% 0 !important;
    }
 }

 @media (min-width: 1200px) { 
    .wrapper{
        padding: 5% 0;
    }
 }

 .title-board{
    width: 40% !important;
 }

 @media (min-width: 390px) { 
    .title-board{
        width: 30% !important;
    }
 }